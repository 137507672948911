import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-navi";
import { Container, Grid } from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import { FormattedMessage } from "react-intl";
import logorb from "../../assets/images/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      "&:hover": {
        color: theme.palette.primary.light,
        transition: "all 250ms linear",
      },
      color: theme.palette.secondary.dark,
      fontWeight: "bold",
      background: "transparent",
      marginLeft: "20px",
      fontSize: "0.95rem",
    },
    container: {
      display: "flex",
      justifyContent: "flex-end",
    },
    menu: {
      width: "100%",
      display: "flex",
    },
    headerImage: {
      "& img": {
        width: "50px",
      },
      paddingTop: "0.5em",
      width: "25px",
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    }
  })
);

const Menu: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.menu} data-testid="menu">
      <Grid container>
        <Grid item xs={3}>
          <Container disableGutters>
            <img src={logorb} alt="logo" className={classes.headerImage} />
          </Container>
        </Grid>
        <Grid item xs={9}>
          <Container disableGutters className={classes.buttonContainer}>
            <Link href="#contactform" style={{ textDecoration: "none" }}>
              <Button className={classes.buttons}>
                <FormattedMessage id="lesmenes.website.header.button.contact" />
              </Button>
            </Link>
            <Hidden mdDown>
              <Link href="#widemessage" style={{ textDecoration: "none" }}>
                <Button className={classes.buttons}>
                  <FormattedMessage id="lesmenes.website.header.button.aboutUs" />
                </Button>
              </Link>
            </Hidden>
            <Button href="mailto:info@lesmenes.com" className={classes.buttons}>
              <FormattedMessage id="lesmenes.website.header.button.sendEmail" />
            </Button>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default Menu;
