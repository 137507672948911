import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "../Menu/Menu";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: "fixed",
      background: "transparent",
      boxShadow: "none",
      width: "100%",
      zIndex: 10,
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.header} Header`} >
      <Container maxWidth="lg">
        <Menu />
      </Container>
    </div >
  );
};
export default Header;
