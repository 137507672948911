import React from 'react';
import { Helmet } from "react-helmet";
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';
import { theme } from './theme';
import { MuiThemeProvider } from '@material-ui/core';
import { IntlProvider } from "react-intl";
import locale_en from "./translations/en.json";
import locale_es from "./translations/es.json";
import locale_ja from "./translations/ja.json";
import locale_pt from "./translations/pt.json";
import locale_zh from "./translations/zh.json";

const data: any = {
  'es': locale_es,
  'en': locale_en,
  'ja': locale_ja,
  'pt': locale_pt,
  'zh': locale_zh,
};

const language = navigator.language.split(/[-_]/)[0];

const App: React.FC = () => {
  return (
    <IntlProvider locale={language} messages={data[language]}>
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="LESMENES" />
            <title>LESMENES</title>
            <link rel="canonical" href="https://lesmenes.com/" />
          </Helmet>
          <Header />
          <Body />
          <Footer />
        </div>
      </ MuiThemeProvider>
    </IntlProvider>
  );
}
export default App;
