import { Card, CardActionArea, CardContent, Grid, makeStyles, Typography, Theme, useTheme } from "@material-ui/core";
import React from "react";

interface IProps {
  message: string;
  finalMessage: string;
  title: string;
  invertAlignment?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  card: {
    flex: 1,
    backgroundColor: "transparent",
    [theme.breakpoints.up('md')]: {
      fontSize: "3rem",
      paddingBottom: "0",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: '0',
    [theme.breakpoints.up('md')]: {
      height: "100vh",
    },
  },
  cardTitle: {
    "&:hover": {
      color: theme.palette.primary.light,
      transition: "all 250ms linear",
    },
    paddingTop: "50px",
    color: theme.palette.secondary.dark,
    width: "100%",
    fontWeight: "bold",
    fontSize: "2rem",
    paddingBottom: "20px",
    [theme.breakpoints.up('md')]: {
      fontSize: "3rem",
      paddingBottom: "0",
    },
  },
  cardTitleAlignLeft: {
    textAlign: "left",
  },
  cardTitleAlignRight: {
    textAlign: "right",
  },
  cardGrid: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  cardMessage: {
    color: theme.palette.primary.light,
    fontWeight: 'normal',
    fontSize: '1.4rem'
  },
  cardMessageAlignRight: {
    textAlign: "right",
    [theme.breakpoints.up('md')]: {
      textAlign: "justify",
    },
  },
  cardMessageAlignLeft: {
    textAlign: "left",
  },
}));

const WideMessage: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const messageAlignmentClass = props.invertAlignment ? classes.cardMessageAlignLeft : classes.cardMessageAlignRight;
  const titleAlignmentClass = props.invertAlignment ? classes.cardTitleAlignRight : classes.cardTitleAlignLeft;
  return (
    <div className="WideMessage" id="widemessage">
      <Grid item xs={12}>
        <CardActionArea component="a" href="#">
          <Card className={classes.card}>
            <div>
              <CardContent className={classes.cardContainer}>
                <Grid item xs={12} className={classes.cardGrid}>
                  <Typography className={`${classes.cardTitle} ${titleAlignmentClass}`} component="h2" variant="h5">
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.cardGrid}>
                  <Typography variant="subtitle1" paragraph className={`${classes.cardMessage} ${messageAlignmentClass}`}>
                    {props.message}<br />
                    {props.finalMessage}
                  </Typography>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </CardActionArea>
      </Grid>
    </div>
  );
};
export default WideMessage;
