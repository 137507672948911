import React from "react";
import "./FixedBackgroundVideo.css"
import videomenes from "./videomenes.webm"

/* interface IProps{
  video:string
}
 */
const FixedBackgroundVideo: React.FunctionComponent = () => {
    return (
      <div className ="fixedvideobackground" data-testid="fixedvideobackground">
        <video autoPlay muted loop>
          <source src={videomenes} type="video/webm" />
        </video>
      </div>
      );
    }

export default FixedBackgroundVideo