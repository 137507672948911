import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "./ContactForm.css";
import { useResource } from "react-request-hook";
import { api } from "../../api";
import { useFormik } from "formik";
import * as yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    "&:hover": {
      background: theme.palette.primary.light,
      transition: "all 250ms linear",
      color: theme.palette.secondary.dark,
    },
    margin: theme.spacing(3, 0, 2),
    background: theme.palette.secondary.dark,
    color: "white",
  },
  text: {
    "&:hover": {
      color: theme.palette.primary.light,
      transition: "all 250ms linear",
    },
    color: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    textTransform: "uppercase",
    paddingBottom: theme.spacing(4),
    fontWeight: "bold",
    fontSize: "3rem",
  },
  labels: {
    color: "white",
    fontFamily: theme.typography.fontFamily,
  },
  borde: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
}));

const ContactForm: React.FC = (props: any) => {
  const [, setForm] = useResource(api.submitForm);
  const classes = useStyles();
  const [formSuccess, setFormSuccess] = useState(false);
  const email_is_valid = props.intl.formatMessage({ id: "lesmenes.website.contactform.email.isValid" });
  const email_is_required = props.intl.formatMessage({ id: "lesmenes.website.contactform.email.isRequired" });
  const firstName_is_valid = props.intl.formatMessage({ id: "lesmenes.website.contactform.firstName.isValid" });
  const firstName_is_required = props.intl.formatMessage({ id: "lesmenes.website.contactform.firstName.isRequired" });
  const message_is_valid = props.intl.formatMessage({ id: "lesmenes.website.contactform.message.isValid" });
  const message_is_required = props.intl.formatMessage({ id: "lesmenes.website.contactform.message.isRequired" });
  const validationSchema = yup.object({
    email: yup.string().email(email_is_valid).required(email_is_required),
    firstName: yup.string().min(3, firstName_is_valid).required(firstName_is_required),
    mensaje: yup.string().min(10, message_is_valid).required(message_is_required),
    lastName: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      mensaje: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (completeForm) => {
      setForm(completeForm);
      formik.resetForm();
      setFormSuccess(true);
    },
  });

  return (
    <div id="contactform" className="contactform" data-testid="contactform">
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3" className={classes.text}>
            {formSuccess ? <FormattedMessage id="lesmenes.website.contactform.ThankYou" /> : <FormattedMessage id="lesmenes.website.contactform.Contact" />}
          </Typography>
          <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  required
                  id="firstName"
                  label={<FormattedMessage id="lesmenes.website.contactform.firstName.label" />}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  InputLabelProps={{
                    style: { color: "#fff" },
                    className: classes.labels,
                  }}
                  InputProps={{
                    className: classes.labels,
                  }}
                  className={classes.borde}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label={<FormattedMessage id="lesmenes.website.contactform.lastName.label" />}
                  onChange={formik.handleChange}
                  name="lastName"
                  value={formik.values.lastName}
                  InputLabelProps={{
                    style: { color: "#fff" },
                    className: classes.labels,
                  }}
                  InputProps={{
                    className: classes.labels,
                  }}
                  className={classes.borde}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label={<FormattedMessage id="lesmenes.website.contactform.email.label" />}
                  required
                  onChange={formik.handleChange}
                  name="email"
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{
                    style: { color: "#fff" },
                    className: classes.labels,
                  }}
                  InputProps={{
                    className: classes.labels,
                  }}
                  className={classes.borde}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="mensaje"
                  label={<FormattedMessage id="lesmenes.website.contactform.message.label" />}
                  onChange={formik.handleChange}
                  value={formik.values.mensaje}
                  error={formik.touched.mensaje && Boolean(formik.errors.mensaje)}
                  helperText={formik.touched.mensaje && formik.errors.mensaje}
                  id="mensaje"
                  required
                  multiline
                  rows={8}
                  InputLabelProps={{
                    style: { color: "#fff" },
                    className: classes.labels,
                  }}
                  InputProps={{
                    className: classes.labels,
                  }}
                  className={classes.borde}
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" className={classes.submit}>
              <FormattedMessage id="lesmenes.website.contactform.button.Submit" />
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default injectIntl(ContactForm);
