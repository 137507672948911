import { request } from "react-request-hook";
import configData from "./config.json";

export type Form = {
  firstName: string;
  lastName: string;
  email: string;
  mensaje: string;
};

export const api = {
  submitForm: (datosForm: Form) => {
    const objetoDiscord = {
      username: datosForm.firstName + " " + datosForm.lastName + " - " + datosForm.email,
      content: datosForm.mensaje,
    };
    return request<any>({
      baseURL: configData.BASE_URL_DISCORD,
      url: configData.URL_DISCORD,
      method: "POST",
      data: objetoDiscord,
    });
  },
  fetchIPGeoData: () => {
    return request<any>({
      baseURL: configData.GEOIP_BASE_URL,
      url: configData.GEOIP_URL + configData.GEOIP_API_KEY,
      method: "GET",
    });
  },
};
