import React from "react";
import WideMessage from "../WideMessage/WideMessage";
import FixedBackgroundVideo from "../FixedBackgroundVideo/FixedBackgroundVideo";
import ContactForm from "../ContactForm/ContactForm";
import { injectIntl } from "react-intl";
import { Container } from "@material-ui/core";

const Body: React.FC = (props: any) => {
  return (
    <div className="Body">
      <FixedBackgroundVideo />
      <Container maxWidth="lg">
        <WideMessage message={props.intl.formatMessage({ id: "lesmenes.website.home.motivateText" })} finalMessage={props.intl.formatMessage({ id: "lesmenes.website.home.motivateFinalText" })} title={props.intl.formatMessage({ id: "lesmenes.website.home.motivateTitle" })} />
        <WideMessage invertAlignment={true} message={props.intl.formatMessage({ id: "lesmenes.website.home.serviceText" })} finalMessage={""} title={props.intl.formatMessage({ id: "lesmenes.website.home.serviceTitle" })} />
        <ContactForm />
      </Container>
    </div>
  );
};
export default injectIntl(Body);
