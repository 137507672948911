import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#00F",
            light: '#ccc',
            dark: '#555',
            contrastText: '#eee',
        },
        secondary: {
            dark: '#f74843',
            main: '#00E',
        }
    },
    typography: {
        body1: {
            fontFamily: "Comic Sans"
        },
        fontFamily: [
            'reforma2018',
            'serif',
        ].join(','),
    }
});

