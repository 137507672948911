import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Link, Typography } from "@material-ui/core";
import logobn from "../../assets/images/logobn2.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(7, 2),
    marginTop: "auto",
    backgroundColor: theme.palette.secondary.dark,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  },
  container: {
    paddingTop: theme.spacing(20),
  },
  footerImage: {
    "& img": {
      width: "75px",
    },
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      width: '190px',
    },
  },
}));

const Copyright = () => {
  return (
    <Typography variant="body2" style={{ color: "white" }}>
      {"Copyright © "}
      <Link color="inherit" href="#">
        LESMENES
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className="Footer">
      <div className={classes.container}>
        <footer className={classes.footer}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.footerImage}>
                  <img src={logobn} />
                </div>
                <Copyright />
              </Grid>
            </Grid>
          </Container>
        </footer>
      </div>
    </div>
  );
};
export default Footer;
